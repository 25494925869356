<template>
  <div class="content pad-bottom--xl">
    <div class="grid-container pad-top bg--white">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <h1>Privacy Policy</h1>
          <p class="marg-top">
            Ipsen is committed to protect your personal data in accordance with applicable privacy and data protection laws, including the UK General Data Protection Regulation (UK GDPR), and other applicable local laws that regulate the storage, processing, access and transfer of personal data.
          </p>
          <p>
            This Privacy Policy describes the ways in which Personal Information about individuals (“Users”) who use this website (the “Website”) may be processed by Ipsen Ltd, 190 Bath Road, Slough, SL1 3XE (“Ipsen”), the data controller and its data processors, including PB Events and CrowdComms.
          </p>
          <h2>
            <strong>Personal data collected</strong>
          </h2>
          <p>
            We will process the following information in order to provide you with access to the event:
          </p>
          <ul>
            <li>Title, first name, last name</li>
            <li>Email address</li>
            <li>Employer</li>
            <li>Password</li>
            <li>Your name, Position / Job title and email will be visible to others attending the event. You can choose to hide and / or amend your information within your profile settings.</li>
          </ul>
          <p>
            The information systems and software used to make the <strong>Website</strong> function collect some personal data that is built in when using the site. This data includes: IP addresses, the type of browser used, the operating system, the domain name and the address of web sites from/to which access/exit was made, information about the pages visited by users within the site, the time of access, how long was spent on each page, an analysis of the internal path and other parameters relating to the operating system and IT environment of the user.
          </p>
          <p>
            Such technical data is gathered and used exclusively on an aggregate and anonymous basis and may be used to determine responsibility in cases of alleged IT/electronic offences causing damage to the Website.
          </p>
          <p>
            This Personal Information does not directly identify you by name but could be used to identify that a specific computer or device has accessed the <strong>Website</strong>.
          </p>
          <p>
            <strong>Use of personal data and legal basis</strong>
          </p>
          <p>
            Your personal data will only be processed for the purposes of the legitimate interests pursued by Ipsen, namely to facilitate your participation in this event, including:
          </p>
          <ul>
            <li>Registering you for the event</li>
            <li>Giving you access to the event sessions</li>
            <li>Managing questions during the event</li>
            <li>Sharing that you’re attending to the other attendees</li>
            <li>Responding to your requests or inquiries</li>
            <li>Performing website analytics and measure website performance</li>
            <li>Maintaining the Website, including for security purposes</li>
          </ul>
          <p>
            Your personal data will not be sold, shared or otherwise distributed to third parties without your consent, except where we are required to do so because of an applicable law, court order or governmental regulation, or if such disclosure is otherwise necessary in support of any criminal or other legal investigation or proceeding here or abroad.
          </p>
          <h2>
            <strong>Personal data recipients</strong>
          </h2>
          <p>
            Your personal data will be shared with Ipsen’s event support organisations including Production Bureau Ltd (Pb)events, and CCIS. Pb use a company called Crowdcomms to provide the technology for the Website and you can find more information about how your data is handled in the system here (<a href="https://www.crowdcomms.com/privacy-policy">https://www.crowdcomms.com/privacy-policy</a>). These organisations do this work on behalf of Ipsen under contract which requires them to comply with data protection legislation.
          </p>
          <p>
            Ipsen may also need to share your personal data with:
          </p>
          <ul>
            <li><strong>Ipsen and its affiliates</strong></li>
            <li><strong>Selected suppliers, service providers or vendors</strong> acting upon Ipsen’s instructions for website hosting, data analysis, order fulfilment, information technology and related infrastructure provision, customer service, email delivery, auditing, etc.</li>
            <li><strong>Legal or administrative authorities:</strong> as required by applicable laws including laws outside your country of residence.</li>
          </ul>
          <h2>
            <strong>Personal data transfers</strong>
          </h2>
          <p>
            Ipsen is a global biopharmaceutical group with affiliates, partners and subcontractors located in many countries around the world.
          </p>
          <p>
            For that reason, Ipsen may need to transfer your Personal Data to other countries, including from the UK or the EU to outside the UK or outside the European Economic Area, in countries which may not be regarded as providing the same level of protection as the UK.
          </p>
          <p>
            In cases that Ipsen needs to transfer Personal Data outside the UK or outside the European Union, it shall ensure that adequate safeguards, as required under applicable data protection legislation, will be implemented (including, notably, Standard Contractual Clauses and International Data Transfer Agreements, as applicable).
          </p>
          <h2>
            <strong>Term of retention of personal data</strong>
          </h2>
          <p>
            Your Personal Information is processed will be retained in this website for 13 months.
          </p>
          <h2>
            <strong>Security</strong>
          </h2>
          <p>
            Your Personal Information will be stored on secure servers and maintained as strictly confidential. You are responsible for maintaining the secrecy of your unique password and account information.
          </p>
          <h2>
            <strong>Your rights</strong>
          </h2>
          <p>
            You have the following rights regarding your information depending on the circumstances and applicable legislation:
          </p>
          <p>
            <strong>The right of access</strong>
          </p>
          <p>
            You have the right to obtain access to the information processed by Ipsen.
          </p>
          <p>
            <strong>The right to rectification</strong>
          </p>
          <p>
            You are entitled to have your information corrected if it is inaccurate or incomplete.
          </p>
          <p>
            <strong>The right to erasure</strong>
          </p>
          <p>
            This is also known as 'the right to be forgotten' and, in simple terms, enables you to request the deletion or removal of your information where there is no compelling reason for Ipsen to keep using it. This is not a general right to erasure; there are exceptions.
          </p>
          <p>
            <strong>The right to restrict processing</strong>
          </p>
          <p>
            You have rights to 'block' or suppress further use of your information in certain circumstances. When processing is restricted, Ipsen can still store your information, but may not use it further.
          </p>
          <p>
            <strong>The right to data portability</strong>
          </p>
          <p>
            You have rights to obtain and reuse your personal data in a structured, commonly used and machine-readable format in certain circumstances.
          </p>
          <p>
            <strong>The right to object</strong>
          </p>
          <p>
            You have the right to object to certain types of processing, in certain circumstances
          </p>
          <p>
            If you would like to exercise any of these rights, please send us a Data Subject right request by completing this form. 
          </p>
          <p>
            If you are not satisfied with the response to your complaint or believe the processing of your information does not comply with data protection law, you can make a complaint to the Information Commissioner’s Office (Home | ICO) or other relevant supervisory authority.
          </p>
          <p>
            If you have any questions or comments about this Privacy Policy , please feel free to email Ipsen's Data Protection Officer at <a href="mailto:dataprivacy@ipsen.com">dataprivacy@ipsen.com</a>.
          </p>
          <h2>
            <strong>Cookies and similar technologies</strong>
          </h2>
          <p>
            <strong>Cookies and similar technologies</strong>
          </p>
          <p>
            This Website uses cookies and similar technologies (“cookies”), including third-party cookies.
          </p>
          <p>
            Cookies are small data files which are placed on your computer, smartphone or other device. Cookies are stored on the hard disk of your device without any risk of damaging it.
          </p>
          <p>
            When you enter the Website, you can:
          </p>
          <ul>
            <li>Accept the use of cookies; or</li>
            <li>Refuse the use of cookies</li>
            <li>Set your Cookies preference</li>
          </ul>
          <p>
            Please consult our Cookies Policy if you like to have more information.
          </p>
          <p class="pad-top">May 2022</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>
